





















































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import { Message } from "element-ui";

@Component({})
export default class AUser extends Vue {
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  btnloading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search: any = {
    id: "",
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  checked = false;
  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post("J30113", this.search)
      .then((data) => {
        this.total = data.total;
        this.tableData = data.list;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  // 弹窗
  powerDialog = false;
  checked2: any = [];
  checkList: any = [];

  // 获取产品
  getUserChannelProduct() {
    api.post("J30121", { channelId: this.$route.query.id }).then((res) => {
      this.checkList = res.list;
    });
  }

  queryUserId = "";
  power(item: any) {
    this.queryUserId = item.userId;
    this.checked2 = [];
    api
      .post("J30115", {
        queryUserId: item.userId,
        channelId: this.$route.query.id,
      })
      .then((res) => {
        this.powerDialog = true;

        res.list.forEach((item: any) => {
          this.checked2.push(item.productId);
        });
      });
  }

  // 保存权限
  save() {
    api
      .post("J30118", {
        queryUserId: this.queryUserId,
        channelId: this.$route.query.id,
        productList: this.checked2,
      })
      .then((res) => {
        this.$message.success("操作成功");
        this.powerDialog = false;
        this.searchTable();
      });
  }

  edit(item: any) {
    this.addInfo.id = item.id;
    this.addInfo.userNumber = item.username;
    this.checked = item.userType == "master" ? true : false;
    this.addInfo.userNumber = item.userNumber
    this.addDialog = true;
  }

  // 添加
  addInfo: any = {
    id: "", // 用户id
    channelId: "", // 渠道id
    userNumber: "",
    userType: "",
  };
  addDialog = false;
  add() {
    this.addInfo.userNumber = ''
    this.addInfo.userType = ''
    this.checked = false
    this.addDialog = true;
  }

  handleClose() {
    this.addDialog = false;
    this.addInfo.id = "";
  }

  addAccount() {
    this.addInfo.userType = this.checked ? "master" : "follower";
    if (!this.addInfo.id) {
      // 添加
      api
        .post("J30114", {
          id: this.search.id,
          userNumber: this.addInfo.userNumber,
          userType: this.addInfo.userType,
        })
        .then((res) => {
          this.searchTable();
          this.addDialog = false;
          this.addInfo.userNumber = "";
        });
    } else {
      // 修改
      api
        .post("J30116", {
          id: this.addInfo.id,
          channelId: this.addInfo.channelId,
          userType: this.addInfo.userType,
        })
        .then((res) => {
          this.searchTable();
          this.addDialog = false;
          this.addInfo.userNumber = "";
          this.addInfo.id = "";
        });
    }
  }

  // 解绑
  remove(item: any) {
    this.$confirm("你确定要解绑该账号, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      this.btnloading = true;
      api
        .post("J30117", { id: item.id })
        .then((res) => {
          this.searchTable();
          Message.success("解绑成功");
        })
        .finally(() => {
          this.btnloading = false;
        });
    });
  }

  activated() {
    let { id } = this.$route.query;
    this.addInfo.channelId = id;
    this.search.id = id;
    this.searchTable();
    this.getUserChannelProduct()
  }
}
